<template>
    <v-timeline-item small class="comment-box" ref="timeline">
        <template v-slot:icon>
            <v-avatar size="40">
                <object :data="`/media/avatar/${comment.author_id}/small`" type="image/jpg">
                    <v-icon size="40" class="avatar-bg">mdi-account-circle</v-icon>
                </object>
            </v-avatar>
        </template>
        <v-card
            :data-comment-id="comment.comment_id"
            :data-author-id="comment.author_id"
            :data-parent-id="comment.parent_id"
            :disabled="isLoading"
            :color="comment.author_id === pharmacist_id ? '#f5f5db' : '#f2f2f2'"
            class="rounded-xl"
        >
            <v-card-title class="justify-space-between">
                {{ comment.author_name }}
                <span v-if="!isEditing">
                    <v-icon
                        v-if="currentUser._id === comment.author_id"
                        @click="isEditing = true"
                        size="27"
                        class="edit-comment"
                    >
                        mdi-pencil
                    </v-icon>
                    <v-icon
                        v-if="currentUser._id === comment.author_id"
                        @click="isDeleteCommentDialogOpen = true"
                        size="30"
                        class="delete-comment"
                    >
                        mdi-close
                    </v-icon>
                    <DeleteCommentConfirmationDialog
                        v-model="isDeleteCommentDialogOpen"
                        @confirm-deletion="deleteComment({
                            'threadId': threadId,
                            'commentId': comment.comment_id,
                            'parentId': comment.parent_id
                        })"
                    />
                </span>
            </v-card-title>
            <v-card-subtitle>
                <span>{{ formatDate(comment.timestamp) }}</span>
                <span class="ml-2">
                    {{ comment.edited ? '(Bearbeitet)' : '' }}
                </span>
            </v-card-subtitle>
            <v-card-text class="pb-0">
                <span v-if="!isEditing" class="text-body-1 text">
                    {{ comment.message }}
                </span>
                <span v-else>
                    <v-text-field
                        @keyup.enter="edit"
                        :value="comment.message"
                        :ref="comment.comment_id"
                        :disabled="isLoading"
                        class="edit-field"
                        outlined
                        hide-details
                        dense
                        rounded
                    ></v-text-field>
                </span>
                <div>
                    <a
                        v-if="!comment.parent_id && !isReplying && !isEditing"
                        @click="isReplying = true"
                        class="d-inline-block reply"
                        link
                    > Antworten
                    </a>
                </div>

            </v-card-text>
            <v-card-actions class="comment-actions">
                <v-btn
                    v-if="isEditing"
                    @click="edit"
                    :disabled="isLoading"
                    class="mt-2 mr-2 rounded-xl"
                    color="primary"
                    small
                >
                    Bearbeiten
                </v-btn>
                <v-btn
                    v-if="isEditing"
                    @click="isEditing = false"
                    small
                    class="mt-2 ml-2 rounded-xl"
                >Abbrechen</v-btn>
            </v-card-actions>
        </v-card>
        <v-timeline
            v-if="replies[comment.comment_id]"
            class="ml-n12 ml-sm-n8 ml-md-0"
            dense
            small
        >
            <PharmacistThreadComment
                v-for="reply in replies[comment.comment_id]"
                :key="reply.comment_id"
                :comment="reply"
                :threadId="threadId"
                :pharmacist_id="pharmacist_id"
            />
        </v-timeline>
        <div v-if="!comment.parent_id && isReplying" class="d-sm-flex align-baseline">
            <v-text-field
                v-model="replyText"
                @keyup.enter="addReply"
                :ref="comment.comment_id"
                :disabled="isLoading"
                class="mt-4 add-reply"
                placeholder="Antworten"
                hide-details
                dense
                outlined
                rounded
            />
            <v-btn
                @click="addReply"
                :disabled="!replyText?.trim().length || isLoading"
                color="primary"
                class="ml-2 mt-2 mt-sm-0 rounded-xl"
                small
            >senden</v-btn>
        </div>
    </v-timeline-item>
</template>
<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import DeleteCommentConfirmationDialog from "./DeleteCommentConfirmationDialog.vue";

export default {
    name: 'PharmacistThreadComment',
    props: {
        comment: Object,
        pharmacist_id: String,
        threadId: String
    },
    components: {
        DeleteCommentConfirmationDialog
    },
    data() {
        return {
            isDeleteCommentDialogOpen: false,
            isEditing: false,
            isLoading: false,
            isReplying: false,
            replyText: ''
        }
    },
    methods: {
        async addReply() {
            if (!this.replyText?.trim().length) return
            this.isLoading = true;

            await this.addCommentReply({
                threadId: this.threadId,
                parentId: this.comment.comment_id,
                text: this.replyText
            });
            this.replyText = '';
            this.isReplying = false;
            this.isLoading = false;
        },

        // Edits a comment/reply
        async edit() {
            const text = this.$refs[this.comment.comment_id].$refs.input.value
            if (!text?.trim().length) return
            this.isLoading = true;

            !this.isReply ? await this.editComment({
                threadId: this.threadId,
                commentId: this.comment.comment_id,
                text
            }) : await this.editCommentReply({
                threadId: this.threadId,
                commentId: this.comment.comment_id,
                parentId: this.comment.parent_id,
                text
            })
            this.isEditing = false;
            this.isLoading = false;
        },

        formatDate(unformatted) {
            let date = unformatted;
            if (!date.endsWith('+02:00')) date += 'Z';
            return moment(date).format('DD-MM-YYYY, HH:mm');
        },

        /* updates the height of the avatar timeline container to be aligned with
        the height of its comment card */
        adjustAvatarheight() {
            const timelineElement = this.$refs.timeline.$el;
            const avatarBox = timelineElement.children[1];
            const commentCard = timelineElement.children[0];
            avatarBox.style.height = commentCard.firstElementChild.offsetHeight + 'px'
        },

        // focus on input field
        focusOnTextField(){
            this.$nextTick(() => {
                const commentId = this.comment.comment_id;
                if (this.$refs[commentId]) {
                    this.$refs[commentId].focus()
                }
            })
        },
        ...mapActions({
            addCommentReply: 'threads/addCommentReply',
            editComment: 'threads/editComment',
            editCommentReply: 'threads/editCommentReply',
            deleteComment: 'threads/deleteComment',
        }),
        ...mapMutations({
            updateComment: 'threads/updateComment',
            updateReply: 'threads/updateReply',
        })
    },
    watch: {
        isEditing(val) {
            if (val) this.focusOnTextField()
        },
        isReplying(val) {
            if (val) this.focusOnTextField()
        }
    },
    computed: {
        isReply() { return this.comment.parent_id },
        ...mapGetters(['currentUser']),
        ...mapState('threads', ['replies']),
    },
    mounted() { this.adjustAvatarheight() },
    updated() { this.adjustAvatarheight() },
}
</script>
<style></style>